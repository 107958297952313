<template>
  <v-dialog :value="dialog" v-if="dialog" persistent max-width="580px" hide-overlay :attach="true" :content-class="'hide-overflow-y image-slider'">
    <v-card>
      <v-card-title class="confirm_headline pt-5" style="text-align: center;display: grid;">
        {{ dialogHeadline }}
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error white--text text-capitalize" dark dense @click="$emit('close')">{{
          $t("confirmationDialog.cancel")
        }}</v-btn>
        <v-btn color="green darken-4 green-gradient white--text text-capitalize" dark dense @click="$emit('confirm')">{{
          $t("confirmationDialog.ok")
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",

  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
    dialogHeadline: {
      type: String,
      default: "",
    },
  },

  computed: {
    dialogStatus: {
      get: function () {
        return this.dialog;
      },
      set: function () {},
    },
  },
};
</script>
<style scoped>
.v-dialog__content {
  position: absolute;
}
</style>
